<template>
  <div>
    <div class="content-header px-0">
      <h1 class="title-I">Usuários cadastrados</h1>
    </div>

    <div
      v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'EMPTY', 'USRADMIN']"
      class="form-inline col-12 p-0"
    >
      <div class="pl-0 col-10 filterInput">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>

          <div class="pl-0 col-11 filterInput">
            <input
              id="userinputfilter"
              v-model="filter"
              type="search"
              class="form-control col-12 w-100"
              placeholder="Buscar"
            />
          </div>
        </b-input-group>
      </div>

      <router-link
        id="adduser"
        title="Adicionar usuário"
        to="/user/add"
        v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'EMPTY', 'USRADMIN']"
        class="btn btn-primary add-user col-2 btt"
      >
        <span class="d-none d-md-block d-lg-block">Adicionar </span>

        <span class="d-block d-md-none d-lg-none">
          <i class="fas fa-plus-circle"></i>
        </span>
      </router-link>
    </div>

    <b-table
      responsive="sm"
      striped
      borderless
      hover
      v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'EMPTY', 'USRADMIN']"
      class="mt-3 rounded"
      no-sort-reset
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum usuário encontrado para essa busca"
      ref="selectableTable"
      sticky-header="610px"
      :tbody-tr-class="inactiveUserRow"
      :filter="filter"
      :busy="load"
      :fields="fields"
      :sort-by.sync="sortBy"
      :items="users"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: (field.key === 'actions' ? '100px' : '550px')}"
        />
      </template>

      <template v-slot:cell(actions)="data">
        <button
          id="stateuser"
          type="button"
          @click="userChange = data.item"
          v-can="['ADMIN', 'SYSADMIN', 'USRADMIN']"
          class="mr-3"
          data-toggle="modal"
          data-target="#ModalStateUser"
          title="Alterar Estado do usuário"
          v-if="storage && !(data.item.roles == 'ADMIN' && !hasRole('ADMIN'))
              ? storage.getItem('email') != data.item.email
              : false"
        >
          <i
            v-if="data.item.status === 'ACTIVE'"
            class="fa fas fa-lg fa-check-circle iconspace"
            title="Ativo"
          ></i>

          <i
            v-if="data.item.status === 'INACTIVE'"
            class="fa fas fa-lg fa-ban iconspace"
            title="Inativo"
          ></i>

          <i
            v-if="data.item.status === 'BLOCKED'"
            class="fa fas fa-lg fa-ban iconspace"
            title="Bloqueado"
          ></i>
        </button>

        <span v-else class="mr-3">
          <i
            v-if="data.item.status === 'ACTIVE'"
            class="fa fas fa-lg fa-check-circle iconspace"
            title="Ativo"
          ></i>

          <i
            v-if="data.item.status === 'INACTIVE'"
            class="fa fas fa-lg fa-ban iconspace"
            title="Inativo"
          ></i>

          <i
            v-if="data.item.status === 'BLOCKED'"
            class="fa fas fa-lg fa-ban iconspace"
            title="Bloqueado"
          ></i>
        </span>
        <a
          id="excluiruser"
          @click="user = data.item"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'EMPTY','USRADMIN']"
          href="#"
          class="mr-3"
          data-toggle="modal"
          data-target="#modalDeleteUser"
          title="Excluir"
          v-if="
            storage && !(data.item.roles == 'ADMIN' && !hasRole('ADMIN'))
              ? storage.getItem('email') != data.item.email
              : false
          "
        >
          <i class="far fa-trash-alt iconspace"></i>
        </a>
        <router-link
          id="editaruser"
          v-if="!(data.item.roles == 'ADMIN' && !hasRole('ADMIN'))"
          :to="{ name: 'user-edit', params: { user: data.item } }"
          v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'EMPTY', 'USRADMIN']"
          title="Editar"
        >
          <i class="fas fa-pen iconspace fa-pull-rigth"></i>
        </router-link>
      </template>

      <template v-slot:cell(cellphone)="data">{{
        formatCellphone(data.item.cellphone)
      }}</template> 

      <template v-slot:cell(roles)="data">{{
        data.item.roles.join(", ")
      }}</template>

      <!-- <template v-slot:cell(status)="data">{{

        data.item.status.join(", ")

      }}</template> -->
    </b-table>

    <b-table
      responsive="sm"
      striped
      v-can="['MOBILE', 'DEVICE', 'DASHBOARD', 'USER']"
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum usuário encontrado para essa busca"
      ref="selectableTable"
      sticky-header="610px"
      :filter="this.name"
      :busy="load"
      :fields="fields"
      :sort-by.sync="sortBy"
      :items="users"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '100px' : '550px' }"
        />
      </template>

      <template v-slot:cell(cellphone)="data">{{
        formatCellphone(data.item.cellphone)
      }}</template>

      <template v-slot:cell(roles)="data">{{
        data.item.roles.join(", ")
      }}</template>
    </b-table>

    <Modal
      id="ModalStateUser"
      @click="userChange = data.item"
      title="Alterar Situação do Usuário"
      v-bind:msg="'Tem certeza que deseja alterar a situação do usuário ' + '?'"
      button="Alterar"
      :method="stateAdministrative"
    />

    <Modal
      id="modalDeleteUser"
      title="Excluir usuário"
      v-bind:msg="'Tem certeza que deseja excluir ' + user.name + '?'"
      button="Excluir"
      :method="deleteUser"
    />

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>

      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >

            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >

            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >

            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";

import { api } from "../../services/api";
import hasRole from "@/utils/hasRole";

export default {
  name: "ManagerUser",
  data() {
    return {
      storage: null,
      user: "",
      userChange: "",
      filterMB: this.name,
      filter: null,
      perPage: 10,
      rows: 1,
      sortBy: "name",
      currentPage: 1,
      name: "",
      fields: [
        { key: "name", sortable: true, label: "Nome" },
        { key: "email", sortable: true, label: "Email" },
        { key: "cellphone", sortable: false, label: "Celular" },
        { key: "user_groups.name", sortable: true, label: "Grupo" },
        { key: "roles", sortable: true, label: "Nível de acesso" },
        { key: "status", sortable: true, label: "Situação" },
        { key: "actions", label: "Ações", sortable: false, class: "actions" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("user/get");

    this.storage =
      localStorage.getItem("rememberMe") === "true"
        ? window.localStorage
        : window.sessionStorage;

    const email =
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("email")
        : sessionStorage.getItem("email");

    api.get(`/users/${email}`).then((response) => {
      this.name = response.data.name;
    });
  },
  methods: {
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    deleteUser() {
      event.preventDefault();
      if (this.user.type === "LOCAL") {
        this.$store.dispatch("user/deleteUser", { email: this.user.email });
      } else {
        this.$store.dispatch("user/deleteExternalUser", { email: this.user.email });
      }
    },
    onFiltered(users) {
      this.rows = users.length;

      this.currentPage = 1;
    },
    stateAdministrative() {
      const userChangeBackup = {...this.userChange}
      userChangeBackup.flip = true;

      if (this.userChange.type === "EXTERNAL"){
        delete userChangeBackup.type;
        delete userChangeBackup.tfa;
        delete userChangeBackup.refresh_token_expiry;
        delete userChangeBackup.access_token_expiry;
        
        this.$store.dispatch("user/updateExternalUser", userChangeBackup);
      }

      else {
        delete userChangeBackup.type;
        delete userChangeBackup.app_state;
        this.$store.dispatch("user/updateUser", userChangeBackup);
      } 
    },
    formatCellphone(cel) {
      let formatted = cel.replace(
        /(\d{1,1})(\d{1})?(\d{1,2})?(\d{1,4})?/,
        function (_, p1, p2, p3, p4) {
          let output = "";

          if (p1) output = `(${p1}`;

          if (p2) output += `${p2})`;

          if (p3) output += ` ${p3}`;

          if (p4) output += ` ${p4}`;

          return output;
        }
      );

      return formatted;
    },
    hasRole,
    inactiveUserRow(item, type) {
      if (item && type === 'row') {
        if (item.status === 'INACTIVE'){
          return "inactive-user";
        }

        else if (item.status === 'BLOCKED'){
          return "blocked-user";
        }
      }
    },
  },
  watch: {
    users() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.users.length;
          return;
        }
        if (ctx.perPage) {
          this.perPage = ctx.perPage;
        }

        if (ctx.sortBy !== "") {
          this.sortBy = ctx.sortBy;
        }

        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }

      this.rows = this.users.length;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    users() {
      return this.$store.getters["user/users"];
    },
  },
  components: {
    Modal,
  },
};
</script>

<style>
  .inactive-user{
  color: #999;
}

.blocked-user{
  color: red;
}
</style>

<style lang="scss" scoped>
.add-user {
  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;
}

@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;

    margin-right: 10px;
  }
}

.fa-check-circle {
  color: rgb(105, 245, 11);
}

.fa-ban {
  color: rgb(238, 0, 0);
}

</style>
