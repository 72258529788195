import { render, staticRenderFns } from "./UpdateTraining.vue?vue&type=template&id=5f4d7333&scoped=true"
import script from "./UpdateTraining.vue?vue&type=script&lang=js"
export * from "./UpdateTraining.vue?vue&type=script&lang=js"
import style0 from "./UpdateTraining.vue?vue&type=style&index=0&id=5f4d7333&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f4d7333",
  null
  
)

export default component.exports