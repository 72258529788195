<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <div class="content-header px-0">
      <h1 class="title-I">Atualizar treinamentos dos colaboradores</h1>
    </div>
    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card direct-chat direct-chat-primary">
        <div class="card-body">
          <div class="p-4 bg-white rounded">
            <div class="row">
              <div class="col-12 pr-4">
                <div class="form-group">
                  <UploadExcel
                    :on-success="handleSuccess"
                    :before-upload="beforeUpload"
                    :draggable="true"
                  />
                </div>
              </div>
            </div>

            <div v-show="tableData.length">
              <div class="content-header px-0">
                <h4>Colaboradores encontrados</h4>
              </div>
              <b-table
                responsive="sm"
                striped
                borderless
                hover
                :fields="fields"
                :items="tableData"
                :busy="!verifyImport"
                class="mt-3 rounded"
                selectable
                @row-selected="onRowSelected"
                select-mode="multi"
                ref="selectableTable"
                editableFields
              >
                <template
                  v-for="field in editableFields"
                  v-slot:[`cell(${field.key})`]="{ item }"
                >
                  <select
                    class="form-control"
                    v-if="field.key === 'gender'"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'g-'+field.key"
                  >
                    <option v-for="opt in generos" :key="opt.gender">
                      {{ opt }}
                    </option>
                  </select>

                  <input
                    type="text"
                    class="form-control"
                    @change="validate(tableData)"
                    v-model="item[field.key]"
                    :key="field.key"
                    :id="'imp-'+field.key"
                  />

                  <span
                    v-if="item.errors"
                    style="color: red"
                    :key="'erro-' + field.key"
                    >{{ item.errors[field.key] }}</span
                  >
                </template>
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <span aria-hidden="true" class="check-symbol">&check;</span>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <strong>
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </strong>
                  </div>
                </template>
                <template v-slot:table-colgroup>
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{
                      width:
                        field.key === 'selected'
                          ? '150px' : 
                        field.key === 'training.expiration' || field.key === 'training.attendence' || field.key === 'training.grade'
                          ? '250px'
                          : '550px',
                    }"
                  />
                </template>
                <template v-slot:cell(invalid)="data">
                  <span v-if="data.item.invalid" class="valid-import badge badge-danger"
                    >Inválido</span
                  >
                  <span v-if="!data.item.invalid" class="valid-import badge badge-success"
                    >Válido</span
                  >
                </template>
                
                <template
              v-slot:cell(training.name)="data"
            >
              <select
                v-model="
                data.item.training.name"
                class="form-control"
                id="trainingSelect"
                :class="data.item.training.errors.name ? 'error-input' : ''"
                @change="changeTraining(data.item.training)"
              >
                <option value="">Selecione</option>
                <option
                  v-for="
                    training in customerTrainings"
                    :key="training.id"
                    :value="training.name"
                    :disabled="training.selected
                  "
                >
                  {{ training.name }}
                </option>
              </select>
              <div v-if="data.item.training.errors.name" class="error-message">
                {{ data.item.training.errors.name }}
              </div>
            </template>
            <template
              v-slot:cell(training.session)="data"
            >
              <select
                v-model="data.item.training.session"
                class="form-control"
                id="trainingScheduleSelect"
                :class="data.item.training.errors.session ? 'error-input' : ''"
                @change="changeSession(data.item.training)"
              >
                <option value="">Selecione</option>
                <option
                  v-for="
                  schedule in data.item.training.schedules"
                  :key="schedule.id"
                  :value="schedule"
                >
                  {{ "Instrutor: " + schedule.instructor + ". Início: " + schedule.t_start + ". Término: " + schedule.t_end}}
                </option>
              </select>
              <div v-if="data.item.training.errors.session" class="error-message">
                {{ data.item.training.errors.session }}
              </div>
            </template>
            <template v-slot:cell(training.expiration)="data">
              <b-form-input
                type="date"
                v-model="data.item.training.expiration"
                disabled
                placeholder="Validade do treinamento"
                id="trainingExpiration"
                :class="data.item.training.errors.expiration ? 'error-input' : ''"
              />
              <div v-if="data.item.training.errors.expiration" class="error-message">
                {{ data.item.training.errors.expiration }}
              </div>
            </template>
            <template
                v-slot:cell(training.attendence)="data"
              >
                <b-form-input
                  type="number"
                  @change="validate(tableData)"
                  v-model="data.item.training.attendence"
                  placeholder="Frequência"
                  id="trainingFrequency"
                  step="1"
                  max="100"
                  min="0"
                  :class="data.item.training.errors.attendence ? 'error-input' : ''"
                />
                <div v-if="data.item.training.errors.attendence" class="error-message">
                  {{ data.item.training.errors.attendence }}
                </div>
            </template>
            <template v-slot:cell(training.grade)="data">
              <b-form-input
                type="number"
                @change="validate(tableData)"
                v-model="data.item.training.grade"
                placeholder="Nota"
                id="trainingGrade"
                max="100"
                min="0"
                :class="data.item.training.errors.grade ? 'error-input' : ''"
              />
              <div v-if="data.item.training.errors.grade" class="error-message">
                {{ data.item.training.errors.grade }}
              </div>
            </template>
              </b-table>

              <div class="row p-1">
                <button
                    id="selectAll"
                    type="button"
                    class="btn btn-outlined btn-primary mr-2"
                    @click="selectAllRows"
                  >
                    Selecionar tudo
                  </button>
                  <button
                    id="desselectAll"
                    type="button"
                    class="btn btn-outlined btn-primary mr-2"
                    @click="clearSelected"
                  >
                    Desselecionar tudo
                </button> 
                <button
                  type="button"
                  class="btn btn-outlined btn-danger mr-2"
                  @click="deleteSelected"
                  id="btn-delete-selected"
                >
                  Remover selecionados
                </button>
                <button id="btn-saveEmployees-selected" @click="saveEmployees" class="btn btn-success px-5" :disabled="load">
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </div>
            <span v-if="!tableData.length" style="color: blue">
              Selecione um arquivo de colaboradores |
              <a v-bind:href="item.loc" download>Modelo de importação xlsx</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExcel from "@/components/UploadExcel/index.vue";
import Breadcrumb from "@/components/Breadcrumb";
import TwInput from '../../components/TwInput.vue';
import { formatDate, formatDateAPI, trimObjFields } from '../../utils/format-fields';

export default {
  components: { Breadcrumb, UploadExcel, TwInput },
  data() {
    return {
      breadcrumbList: [],
      tableData: [],
      tableHeader: [],
      storage: null,
      employeeModal: false,
      user: "",
      sortBy: "name",
      currentPage: 1,
      selectedEmployee: {},
      fields: [
        { key: "selected", sortable: false, label: "Selecionado" },
        {
          key: "name",
          editable: true,
          sortable: true,
          label: "Nome",
        },
        {
          key: "registration_number",
          editable: true,
          sortable: false,
          label: "Número de Registro",
        },
        {
          key: "training.name",
          label: "Treinamento",
        },
        {
          key: "training.session",
          label: "Turma",
        },
        {
          key: "training.expiration",
          label: "Expiração",
        },
        {
          key: "training.attendence",
          label: "Frequência",
        },
        {
          key: "training.grade",
          label: "Nota",
        },
        {
          key: "invalid",
          label: "Validade",
        },
      ],
      fieldsHeader: [
        {
          key: "name", label: "Nome",
        },
        {
          key: "registration_number", label: "Número de Registro",
        },
        {
          key: "training.name", label: "Nome do Treinamento",
        },
        {
          key: "training.instructor", label: "Instrutor",
        },
        {
          key: "training.t_start", label: "Horário de Início",
        },
        {
          key: "training.t_end", label: "Horário de Fim",
        },
        {
          key: "training.session", label: "Turma",
        },
        {
          key: "training.expiration", label: "Expiração",
        },
        {
          key: "training.attendence", label: "Frequência",
        },
        {
          key: "training.grade", label: "Nota",
        },
      ],
      selected: [],
      item: {
        title: "Excel template",
        loc: "/assets/files/model_import_update_employees.xlsx",
      },
    };
  },
  watch: {
    customer() {
      if (this.customer) {
        this.updateList();
      }
    },
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    verifyImport(){
      if (this.verifyImport)
        this.validate(this.tableData);
    }
  },
  mounted() {
    this.updateList();

    if (this.$route.params.customerId) {
      this.$store.dispatch("customer/getCustomer", {
        customerId: this.$route.params.customerId,
      });
      this.$store.dispatch("employee/getEmployees", {
        customer_id: this.$route.params.customerId,
      });
      this.$store.dispatch("training/getCustomerAllTrainings", {
        customer_id: this.$route.params.customerId,
      });
    } else {
      window.frontMsg(
        "FRNT_MISSING_WARNING",
        { frontelement: "do Cliente" }
      );
      this.$router.push("/");
    }
  },
  methods: {
    saveEmployees() {
      let dadosSelecionados;

      if (this.selected.length > 0){
        dadosSelecionados = this.selected;
      }
      else {
        window.frontMsg(
          "FRNT_MUST_SELECT",
          { field: "Colaborador"}
        );

        return;
      }
      if (this.validate(dadosSelecionados)) {
        let sendEmployees = [...dadosSelecionados];

        sendEmployees.forEach((element) => {
          element.registration_number = element.registration_number.toString();
           
          element.employee_name = element.name;
          element.name = element.training.name;
          element.t_start = element.training.session.t_start;
          element.t_end = element.training.session.t_end;
          element.instructor = element.training.session.instructor;
          element.duration = element.training.duration;
          element.attendence = element.training.attendence;
          element.grade = element.training.grade;
          element.expiration = formatDateAPI(element.training.expiration);
          element.id = element.training.id;
          
          delete element.invalid;
          delete element.errors;
          delete element.training;
        });
        
        this.$store.dispatch("employee/updateListEmployee", {
          customer_id: this.$route.params.customerId,
          data: { trainings: sendEmployees },
        });

        this.deleteSelected();

      } else {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Colaboradores"}
        );

        this.validate(this.tableData)
        return;
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows(){
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
        this.$refs.selectableTable.clearSelected()
    },
    deleteSelected() {
      this.tableData = this.tableData.filter((e) => {
        return !this.selected.includes(e);
      });
      this.$refs.selectableTable.refresh();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;

      if (isLt1M) {
        return true;
      }

      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      if (results.length < 50) {
        this.tableData = this.transformDotNotation(this.changeKeys(results));

        this.tableHeader = header;
        this.validate(this.tableData);

        for (let employee of this.tableData){
          this.onSelectTraining(employee.training)
          this.onSelectSession(employee.training)
        }
      } else {
        window.frontMsg(
          "FRNT_ACTION_WARNING",
          {
            reason: "a quantidade de linhas na planilha excede o limite de 50",
          }
        );
      }
    },
    transformDotNotation(data) {
      let transformedData = [];
      data.forEach((element) => {
        let transformedElement = {};
        for (let key in element) {
          let keyArray = key.split(".");
          let currentElement = transformedElement;
          for (let i = 0; i < keyArray.length; i++) {
            if (i === keyArray.length - 1) {
              currentElement[keyArray[i]] = element[key];
            } else {
              if (!currentElement[keyArray[i]]) {
                currentElement[keyArray[i]] = {};
              }
              currentElement = currentElement[keyArray[i]];
            }
          }
        }

        trimObjFields(transformedElement)
        this.adaptTrainingData(transformedElement)

        transformedData.push(transformedElement);
      });
      return transformedData;
    },
    changeKeys(obj_array) {
      obj_array.forEach((elem) => {
        Object.keys(elem).forEach((key) => {
          for (let y in this.fieldsHeader) {
            if (key.toLowerCase() == this.fieldsHeader[y].label.toLowerCase()) {
              /* Create new key with the same values/properties */
              Object.defineProperty(
                elem,
                this.fieldsHeader[y].key,
                Object.getOwnPropertyDescriptor(elem, key)
              );
              /*Delete old key*/
              delete elem[key];
              break;
            }
          }
        });
      });

      return obj_array;
    },
    adaptTrainingData(employee){
      if (employee.training){
        employee.training.name = employee.training.name?.toUpperCase()
        employee.training.instructor = employee.training.instructor?.toUpperCase()
        
        if (employee.training.instructor ||
            employee.training.t_start ||
            employee.training.t_end
          ){
            employee.training.session = {
            instructor: employee.training.instructor,
            t_start: employee.training.t_start,
            t_end: employee.training.t_end,
          }
        }

        // this.onSelectSession(employee.training)

        employee.training.errors = {
          name: "",
          session: "",
          expiration: "",
          attendence: "",
          grade: "",
        }
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.customer) {
        this.breadcrumbList[1].label = `Editar cliente ${this.customer.company_name}`;
      }
    },
    createErrorAttribute(employee){
      employee.errors = {
          name: null,
          registration_number: null,
          trainings: null,
        };

        employee.invalid = false;
    },
    getFieldLabel(fieldKey){
      for (let field of this.fields)
        if (field.key === fieldKey) return field.label
        
    },
    verifyDoubleData(employee, employeeList, attr, initialIndex){
      let employeeAttribute = employee[attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.

      // Checks if the data isn't empty. 
      if (employeeAttribute){
        for (let j = parseInt(initialIndex)+1; j < employeeList.length; j++){
          //if (employeeList[j][attr]) continue;

          let employeeAttributeCompared = employeeList[j][attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.
          // Checks if has another data with the same input in the table.
          if (employeeAttributeCompared && employeeAttribute === employeeAttributeCompared) {
            //employee.invalid = true;
            employeeList[j].invalid = true;

            // Insert the error message in both inputs.
            //employee.errors[attr] = `${this.getFieldLabel(attr)} duplicado nesta importação`; 
            employeeList[j].errors[attr] = `${this.getFieldLabel(attr)} duplicado nesta importação`;
          }
        }
      }
    },
    verifyEmptyInput(employee, attr){
      employee[attr] = employee[attr]?.toString().trim(); // Backup variable without spaces in start and end of the string.

      if (!employee[attr]) {
        employee.errors[attr] = `${this.getFieldLabel(attr)} não pode ser vazio`;
        employee.invalid = true;
      }
    },
    verifyRegisteredData(employee, attr){
      let checkAttribute = (obj) => obj[attr] ? obj[attr] === employee[attr]?.toString() : false;

      if (employee[attr] && !this.employees.some(checkAttribute)) {
        employee.invalid = true;
        employee.errors[attr] = `${this.getFieldLabel(attr)} não está cadastrado no sistema.`;
      }
    },
    verifyInputLength(employee, attr, minLen, maxLen){
      if (
        (employee[attr]?.toString() &&
            employee[attr]?.toString().length < minLen) ||
            employee[attr]?.toString().length > maxLen
          ) {
        employee.invalid = true;
        employee.errors[attr] = `${this.getFieldLabel(attr)} inválido`;
      }
    },
    isEmployeeValid(employeeList, field, index){
      let employee = employeeList[index]
      if (field === "name"){
        this.verifyEmptyInput(employee, field);
        this.verifyRegisteredData(employee, field);
      }

      else if (field === "registration_number"){
        this.verifyEmptyInput(employee, field);
        this.verifyRegisteredData(employee, field);

        let matchEmployee = this.employees.filter((employee) => 
          employee.name === employeeList[index].name && employee.registration_number === employeeList[index].registration_number).length

        if (matchEmployee === 0 && !employee.errors.name && !employee.errors.registration_number){
          employee.errors.name = "Nome e número de registro não são compatíveis.";
          employee.errors.registration_number = "Nome e número de registro não são compatíveis.";
          employee.invalid = true;
        }
      }

      if (!this.isTrainingValid(employee.training)){
        employee.invalid = true;
      }
      
      this.$refs.selectableTable.refresh
      return !employee.invalid;
    },
    validate(employeeList) {
      // This for-loop configures the attributes of errors and the CPF data.
      for (let employee of this.tableData) {
        employee.name = employee.name?.trim();
        employee.email = employee.email?.trim();
        this.createErrorAttribute(employee)
      }

      for (let field of this.fields){
        if (field.key != "selected" && field.key != "invalid"){ // Those fields doesn't need to be verificated.
          for (let index in employeeList){
            this.isEmployeeValid(employeeList, field.key, index)
          }
        }
      }

      const valido = !this.tableData.some((e) => e.invalid === true);

      this.$refs.selectableTable.refresh();
      return valido;
    },
    removeRow(index) {
      if(this.selectedEmployee.trainings[index].id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == this.selectedEmployee.trainings[index].id){
            training.selected = false;
          }
        }
      }

      this.selectedEmployee.trainings.splice(index, 1);
      this.$refs.employee_trainings_table.refresh();
    },
    onSelectTraining(item){
      if(item.id !== 0){
        for (let training of this.customerTrainings){
          if(training.id == item.id){
           training.selected = false;
          }
        }
      }
      if(item.name !== ""){
        for (let training of this.customerTrainings){
          if(training.name == item.name){
           item.id = training.id;
           item.duration = training.duration;
           item.schedules = training.schedules;
           item.expires_at = training.expires_at;
           training.selected = true;
          }
        }
      } else {
        item.id = 0;
        item.duration = 0;
        item.schedules = [];
      }
    },
    changeTraining(item){
      this.onSelectTraining(item)
      this.validate(this.tableData)
    },
    onSelectSession(item){
      let sche = item.session;

      if (sche){
        let end_date = new Date(formatDate(sche.t_end?.split(" ")[0]) + "T" + sche.t_end?.split(" ")[1]+"Z");
        end_date.setDate(end_date.getUTCDate() + item.expires_at);
        item.expiration = end_date.toISOString()?.split("T")[0];
      }

      else {
        item.expiration = "";
      }
    },
    changeSession(item){
      this.onSelectSession(item)
      this.validate(this.tableData)
    },
    isTrainingValid(training){
      training.errors = {
        name: "",
        session: "",
        expiration: "",
        attendence: "",
        grade: "",
      }

      let valid = true;

      let trainingNameOccurrences = this.customerTrainings.filter(trainingDb => trainingDb.name === training.name?.trim())
      let trainingSessionOccurrences = this.customerTrainings.filter(trainingDb => (
        trainingDb.name === training.name?.trim() &&
        trainingDb.schedules.filter((trainingSchedule) => (
          trainingSchedule.instructor === training.session.instructor &&
          trainingSchedule.t_start === training.session.t_start &&
          trainingSchedule.t_end === training.session.t_end
          )
        ).length > 0
      )).length

      if (trainingNameOccurrences.length === 0){
        training.name = ""
      }

      else {
        training.expires_at = trainingNameOccurrences[0].expires_at;
      }

      if (trainingSessionOccurrences === 0){
        training.session = ""
      }

      else {
        this.onSelectSession(training)
      }

      if (!training.name?.trim() || training.name === "Selecione"){
        training.errors.name = "Treinamento não pode ser vazio."
        valid = false
      }

      if (!training.session || training.session === "Selecione"){
        training.errors.session = "Turma não pode ser vazia."
        valid = false
      }

      if (!training.expiration){
        training.errors.expiration = "Data de expiração não pode ser vazio."
        valid = false
      }
      
      else if (
          new Date(training.expiration) < new Date("01/01/1900") ||
          new Date(training.expiration) > new Date("01/01/2099")
        ) {
          training.errors.expiration = "Data de expiração fora do intervalo aceito."
          valid = false
      }
      

      if (training.attendence === null || training.attendence === undefined || training.attendence === ""){
        training.errors.attendence = "Frequência não pode ser vazia."
        valid = false
      }

      else if (training.attendence < 0 || training.attendence > 100){
        training.errors.attendence = "Frequência deve ser um valor entre 0 a 100.";
        valid = false;
      }

      if (training.grade === null || training.grade === undefined || training.grade === ""){
        training.errors.grade = "Nota não pode ser vazia."
        valid = false
      }
      
      else if (training.grade < 0 || training.grade > 100){
        training.errors.grade = "Nota deve ser um valor entre 0 a 100.";
        valid = false;
      }

      return valid;
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    customer() {
      return this.$store.getters["customer/customer"];
    },
    editableFields() {
      return this.fields.filter((field) => field.editable);
    },
    employees() {
      return this.$store.getters["employee/employees"];
    },
    verifyImport() {
      return this.$store.getters["employee/verifyImport"];
    },
    customerTrainings(){
      return this.$store.getters["training/trainings"];
    },
  },
};
</script>

<style lang="scss" scoped>
      .check-symbol{
    font-size: 3rem;
    margin-top: -30px;
    margin-left: 15px;
    position: absolute;
    display: block;
    text-align: center;
    user-select: none;
  }

  .valid-import{
    width: 100px;
    font-size: 20px;
    padding: 10px;
    border-radius: 50px;
    transition: none;
  }

  .error-message{
    font-size: 10px;
    color: red;
    padding-left: 3px;
  }

  .error-input{
    border: solid 2px red;
  }
</style>
